var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import theme from '../../../Theming/DefaultTheme';
import { CodeBlock, dracula } from 'react-code-blocks';
var careerDayContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 60,
    minHeight: '60vh',
};
var wormholeContainerStyle = {
    position: 'relative',
    width: '100%',
    height: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#000',
    overflow: 'hidden',
};
var circleStyle = {
    position: 'absolute',
    borderRadius: '50%',
    border: '2px solid white',
    animation: 'wormhole 5s linear infinite',
};
var CDCoolExampleSection = function () {
    return (_jsxs(Box, { style: careerDayContainerStyle, children: [_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsxs(Box, { style: wormholeContainerStyle, children: [_jsx(Box, { style: __assign(__assign({}, circleStyle), { width: 100, height: 100, animationDelay: '0s' }) }), _jsx(Box, { style: __assign(__assign({}, circleStyle), { width: 200, height: 200, animationDelay: '1s' }) }), _jsx(Box, { style: __assign(__assign({}, circleStyle), { width: 300, height: 300, animationDelay: '2s' }) }), _jsx(Box, { style: __assign(__assign({}, circleStyle), { width: 400, height: 400, animationDelay: '3s' }) }), _jsx(Box, { style: __assign(__assign({}, circleStyle), { width: 500, height: 500, animationDelay: '4s' }) })] }) }), _jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsxs(Box, { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 10,
                            }, children: [_jsx(Typography, { variant: 'h4', style: { color: theme.palette.common.white }, children: "What kind of things are possible with code?" }), _jsx(CodeBlock, { text: "\n                <style>\n                {'\n                  @keyframes wormhole {\n                    0% {\n                      transform: scale(0);\n                      opacity: 1;\n                    }\n                    100% {\n                      transform: scale(3);\n                      opacity: 0;\n                    }\n                  }\n                '}\n              </style>", language: 'html', showLineNumbers: true, startingLineNumber: 1, theme: dracula })] }) })] }), _jsx("style", { children: "\n          @keyframes wormhole {\n            0% {\n              transform: scale(0);\n              opacity: 1;\n            }\n            100% {\n              transform: scale(3);\n              opacity: 0;\n            }\n          }\n        " })] }));
};
export default CDCoolExampleSection;
