import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Typography, useMediaQuery, useTheme, } from '@mui/material';
import AppIconButton from '../buttons/AppIconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub, faGoogle, } from '@fortawesome/free-brands-svg-icons';
var headerSectionStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh',
    width: '100%',
};
var socialIconsContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
};
var HeaderSection = function () {
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsxs(Box, { style: headerSectionStyles, children: [_jsx(Avatar, { alt: 'Evan-Michael Ellison', src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/headshot_icon.jpg' // Replace with the actual path to your headshot
                , sx: {
                    width: 120,
                    height: 120,
                    marginBottom: 8,
                } }), _jsx(Typography, { variant: 'h1', fontSize: isMobile ? '3rem' : '4rem', mb: 3, style: { color: theme.palette.common.white }, children: "Evan-Michael Ellison" }), _jsx(Typography, { variant: 'h3', style: { color: theme.palette.common.white }, children: "Full Stack Software Engineer" }), _jsxs(Box, { style: socialIconsContainer, children: [_jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faLinkedinIn, style: { color: theme.palette.common.white } }), onClick: function () {
                            return window.open('https://www.linkedin.com/in/evan-michael-ellison-93a5776b/', '_blank');
                        } }), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faGithub, color: 'white', style: { color: theme.palette.common.white } }), onClick: function () {
                            return window.open('https://github.com/EvanMEllison', '_blank');
                        } }), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faGoogle, color: 'white', style: { color: theme.palette.common.white } }), onClick: function () {
                            return window.open('mailto:EvanEllison@codefronttechnologies.com');
                        } })] })] }));
};
export default HeaderSection;
