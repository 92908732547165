import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import theme from '../../../Theming/DefaultTheme';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppIconButton from '../../buttons/AppIconButton';
import TextHighlight from '../../TextHighlight';
var careerDayContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
};
var dividerStyle = {
    width: 120,
    margin: '0px 0px 20px 0px',
};
var CDMyWorkSection = function () {
    var navigate = useNavigate();
    var handleNavigate = function (path, hash) {
        navigate(path);
        if (hash) {
            window.location.hash = hash;
        }
    };
    return (_jsx(Box, { style: careerDayContainerStyle, children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { size: { lg: 7, xs: 12 }, children: _jsxs(Grid, { container: true, spacing: 0, children: [_jsx(Grid, { size: { xs: 6 }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/ms-collage-img1.jpg', style: { width: '100%', height: '100%' }, alt: 'Software Engineer' }) }), _jsx(Grid, { size: { xs: 6 }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/ms-collage-img2.jpg', style: { width: '100%', height: '100%' }, alt: 'Software Engineer' }) }), _jsx(Grid, { size: { xs: 6 }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/ms-collage-img3.jpg', style: { width: '100%', height: '100%' }, alt: 'Software Engineer' }) }), _jsx(Grid, { size: { xs: 6 }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/ms-collage-img4.jpg', style: { width: '100%', height: '100%' }, alt: 'Software Engineer' }) })] }) }), _jsx(Grid, { size: { lg: 5, xs: 12 }, children: _jsxs(Box, { style: {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 120,
                        }, children: [_jsx(Typography, { variant: 'h2', style: { color: theme.palette.common.white }, children: "Where I Work" }), _jsx(Divider, { style: dividerStyle }), _jsxs(Typography, { variant: 'body1', style: { color: theme.palette.common.white }, children: ["My role at Microsoft is a", ' ', _jsx(TextHighlight, { children: "HiSoftware Engineer II" }), ". Microsoft is a big company that makes software, like the Microsoft Office Suite, and hardware, like the Xbox gaming console.", _jsx("br", {}), _jsx("br", {}), "I work on a few teams within the Microsoft internal financial compliance team. Usually people work on one team but because the team I'm on has created and launched a couple of successful application within our Organization, the leaders have looked to us to help with some other projects, particularly", ' ', _jsx(TextHighlight, { children: "AI focused projects" }), ".", _jsx("br", {}), _jsx("br", {}), "Most of what I do each day is centered around programming but there is a lot that engineers, particularly at large tech companies, have to do besides coding:", _jsx("br", {}), _jsx("br", {}), _jsxs("ul", { children: [_jsx("li", { children: "Review new security guidelines and make sure our applications comply with them." }), _jsx("li", { children: "Reviewing other people\u2019s code and providing feedback." }), _jsx("li", { children: "Attend lots of planning meetings" }), _jsx("li", { children: "Writing documentation for software projects" }), _jsx("li", { children: "Testing software to ensure it works correctly" }), _jsx("li", { children: "Providing support and troubleshooting issues" })] })] })] }) }), _jsx(Grid, { size: { lg: 12, xs: 12 }, children: _jsx(Box, { style: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: 200,
                        }, children: _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faCircleArrowDown, color: 'white', style: {
                                    color: theme.palette.common.white,
                                    fontSize: '2rem',
                                } }), onClick: function () {
                                return handleNavigate('/career-day', '#career-day-fact-2');
                            } }) }) })] }) }));
};
export default CDMyWorkSection;
