import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import theme from '../../../Theming/DefaultTheme';
import TextHighlight from '../../TextHighlight';
var careerDayContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 40,
    minHeight: '60vh',
};
var CDHeaderSection = function () {
    return (_jsx(Box, { style: careerDayContainerStyle, children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { size: { lg: 12, xs: 12 }, mb: 6, children: _jsx(Box, { style: { display: 'flex', justifyContent: 'center' }, children: _jsx(Typography, { variant: 'h2', style: { color: theme.palette.common.white }, children: "What is a Software Engineer?" }) }) }), _jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsxs(Typography, { variant: 'body1', style: { color: theme.palette.common.white }, children: ["Think of us as ", _jsx(TextHighlight, { children: "builders" }), " or", ' ', _jsx(TextHighlight, { children: "architects" }), ", but instead of constructing buildings, we create programs, apps, and games that you use every day.", _jsx("br", {}), _jsx("br", {}), "We write", ' ', _jsx(TextHighlight, { children: "instructions, called code, that tells computers, phones, or even gaming consoles what to do" }), ". It's like writing a recipe, but instead of making food, we're making technology work the way we want it to.", _jsx("br", {}), _jsx("br", {}), "For example, when you're using social media, playing video games, or watching videos, there are engineers behind the scenes who wrote the code to make all of that possible.", ' ', _jsx(TextHighlight, { children: "We solve problems and figure out how to make things faster, smarter, and easier for everyone to use" }), "."] }) }), _jsx(Grid, { size: { lg: 1 }, children: _jsx(Divider, { orientation: 'vertical', flexItem: true, style: {
                            backgroundColor: theme.palette.common.white,
                            height: '100%',
                            width: 2,
                        } }) }), _jsx(Grid, { size: { lg: 5, xs: 12 }, children: _jsx(Box, { style: {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/cd_image_engineer.jpg', style: { width: '100%', height: 'auto' }, alt: 'Software Engineer' }) }) })] }) }));
};
export default CDHeaderSection;
