import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import PageSection from '../components/sections/PageSection';
import HeaderSection from '../components/sections/HeaderSection';
import ProjectsSection from '../components/sections/ProjectsSection';
import CareerTimelineSection from '../components/sections/CareerTimelineSection';
import QuoteSection from '../components/sections/QuoteSection';
// @ts-expect-error  This package does not have a types file
import { AnimatedBackground } from 'animated-backgrounds';
var pageContainerStyle = {
    width: '100%',
    paddingTop: '64px', // Adjust this value based on the height of your AppBar
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    padding: 0,
};
var Home = function () {
    return (_jsxs(Container, { style: pageContainerStyle, maxWidth: false, children: [_jsx(AnimatedBackground, { animationName: 'starryNight' }), _jsx(PageSection, { sectionId: 'home', children: _jsx(HeaderSection, {}) }), _jsx(PageSection, { sectionId: 'projects', style: {
                    padding: 0,
                }, children: _jsx(ProjectsSection, {}) }), _jsx(PageSection, { sectionId: 'projects', style: { padding: 0 }, children: _jsx(QuoteSection, {}) }), _jsx(PageSection, { sectionId: 'career-timeline', style: {
                    padding: 0,
                }, children: _jsx(CareerTimelineSection, {}) })] }));
};
export default Home;
