import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import theme from '../../../Theming/DefaultTheme';
var careerDayContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
    padding: 0,
};
var sectionBoxStyle = {
    width: '60vw',
};
var CDEngineerFactsSection = function (_a) {
    var title = _a.title, fact = _a.fact;
    return (_jsxs(Box, { style: careerDayContainerStyle, children: [_jsx(Typography, { variant: 'h3', mb: 3, fontWeight: 700, style: { color: theme.palette.common.black }, children: title }), _jsx(Box, { style: sectionBoxStyle, children: fact })] }));
};
export default CDEngineerFactsSection;
