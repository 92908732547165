import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
var careerDayContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
    padding: 0,
};
var sectionBoxStyle = {
    width: '60vw',
};
var CDHowToStartSection = function () {
    return (_jsxs(Box, { style: careerDayContainerStyle, children: [_jsx(Typography, { variant: 'h4', align: 'center', sx: { mb: 4 }, children: "So what are some things that you can do to get into engineering?" }), _jsx(Box, { style: {
                    width: '60%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    paddingRight: 50,
                }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/kids-coding.png', style: { width: '100%' }, alt: 'Software Engineer' }) })] }));
};
export default CDHowToStartSection;
