import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import theme from '../../../Theming/DefaultTheme';
import TextHighlight from '../../TextHighlight';
var careerDayContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
};
var dividerStyle = {
    width: 120,
    margin: '0px 0px 20px 0px',
};
var CDHowIStartedSection = function () {
    return (_jsx(Box, { style: careerDayContainerStyle, children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { size: { lg: 5, xs: 12 }, children: _jsxs(Box, { style: {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '120px 80px 80px 80px',
                        }, children: [_jsx(Typography, { variant: 'h2', style: { color: theme.palette.common.white }, children: "How It Started" }), _jsx(Divider, { style: dividerStyle }), _jsxs(Typography, { variant: 'body1', style: { color: theme.palette.common.white }, children: ["The beginning of my software engineering journey began, like many engineers,", ' ', _jsx(TextHighlight, { children: "with a love of building things" }), ". As a kid, I would play with K'Nex and build all kinds of crazy contraptions. I just loved to create things and seeing them come to life.", _jsx("br", {}), _jsx("br", {}), "When I got to high school, that love for creating things continued. My school started offering a", ' ', _jsx(TextHighlight, { children: "computer programming course" }), " and I decided to give it a try. The course I took was intro to programming and I was absolutely horrible. I don't think I ever got a grade above a C in that class. Despite that, I loved the challenge. I have always been the kind of person to love a challenge. Ultimately I ended up learning a lot and going on to take Computer Science as my major in college which was a challenge as well.", _jsx("br", {}), _jsx("br", {}), "One thing that really helped me build an engineering mindset was my early days of novice programming outside of school. A couple of examples are ", _jsx(TextHighlight, { children: "MySpace" }), " and", ' ', _jsx(TextHighlight, { children: "Counter Strike" }), ".", _jsx("br", {}), _jsx("br", {}), "Back in the day, MySpace allowed users to modify the HTML code on their pages to really make them custom designed for you. Counter Strike allowed you to modify the fame's code to change gun and player skins to make things look like anything you wanted. I spent hours tinkering with those platforms and learning how to code."] })] }) }), _jsx(Grid, { size: { lg: 1 }, children: _jsx(Divider, { orientation: 'vertical', flexItem: true, style: {
                            backgroundColor: theme.palette.common.white,
                            height: '100%',
                            width: 2,
                        } }) }), _jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsx(Box, { style: {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            paddingRight: 50,
                        }, children: _jsx("img", { src: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/career-day-throwback.png', style: { width: '100%' }, alt: 'Software Engineer' }) }) })] }) }));
};
export default CDHowIStartedSection;
