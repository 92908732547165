import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container, Typography } from '@mui/material';
import CDHeaderSection from '../components/sections/CareerDayPageSections/CDHeaderSection';
import PageSection from '../components/sections/PageSection';
import theme from '../Theming/DefaultTheme';
import CDMyWorkSection from '../components/sections/CareerDayPageSections/CDMyWorkSection';
import CDEngineerFactsSection from '../components/sections/CareerDayPageSections/CDEngineerFactsSection';
import CDHowIStartedSection from '../components/sections/CareerDayPageSections/CDHowIStartedSection';
import TextHighlight from '../components/TextHighlight';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppIconButton from '../components/buttons/AppIconButton';
import { useNavigate } from 'react-router-dom';
// @ts-expect-error This package does not have a types file
import { AnimatedBackground } from 'animated-backgrounds';
import CDHowToStartSection from '../components/sections/CareerDayPageSections/CDHowToStartSection';
import CDCoolExampleSection from '../components/sections/CareerDayPageSections/CDCoolExampleSection';
var pageContainerStyle = {
    width: '100%',
    paddingTop: '64px', // Adjust this value based on the height of your AppBar
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    padding: 0,
};
var CareerDay = function () {
    var navigate = useNavigate();
    var handleNavigate = function (path, hash) {
        navigate(path);
        if (hash) {
            window.location.hash = hash;
        }
    };
    return (_jsxs(Container, { style: pageContainerStyle, maxWidth: false, children: [_jsx(AnimatedBackground, { animationName: 'starryNight' }), _jsx(PageSection, { sectionId: 'career-day-home', style: { width: '70vw', minHeight: '85vh' }, children: _jsxs(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }, children: [_jsx(CDHeaderSection, {}), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faCircleArrowDown, color: 'white', style: {
                                    color: theme.palette.common.white,
                                    fontSize: '2rem',
                                } }), onClick: function () { return handleNavigate('/career-day', '#career-day-fact-1'); } })] }) }), _jsx(PageSection, { sectionId: 'career-day-fact-1', style: {
                    background: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
                    padding: 0,
                    minHeight: '85vh',
                }, children: _jsxs(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }, children: [_jsx(CDEngineerFactsSection, { title: 'Cool Fact #1', fact: _jsxs(Typography, { variant: 'h4', style: {
                                    color: theme.palette.common.black,
                                    textAlign: 'center',
                                }, children: ["Software engineering is one of the", ' ', _jsx(TextHighlight, { children: "highest-paying professions" }), ". In the United States, the average software engineer's salary is around $110,000 per year, but it can range from $80,000 to over $200,000 depending on location, experience, and company. In tech hubs like ", _jsx(TextHighlight, { children: "Silicon Valley" }), ", salaries are significantly higher. Senior engineers at major tech companies like Google, Apple, or Facebook can earn over $200,000 in base salary, with total compensation packages sometimes exceeding $500,000 when including bonuses and stock options."] }) }), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faCircleArrowDown, color: 'white', style: {
                                    color: theme.palette.common.black,
                                    fontSize: '2rem',
                                } }), onClick: function () { return handleNavigate('/career-day', '#career-day-my-work'); } })] }) }), _jsx(PageSection, { sectionId: 'career-day-my-work', style: {
                    background: 'transparent',
                    padding: 0,
                }, children: _jsx(CDMyWorkSection, {}) }), _jsx(PageSection, { sectionId: 'career-day-fact-2', style: {
                    background: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
                    padding: 0,
                    minHeight: '85vh',
                }, children: _jsxs(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }, children: [_jsx(CDEngineerFactsSection, { title: 'Cool Fact #2', fact: _jsxs(Typography, { variant: 'h4', style: {
                                    color: theme.palette.common.black,
                                    textAlign: 'center',
                                }, children: ["The U.S. Bureau of Labor Statistics projects that", ' ', _jsx(TextHighlight, { children: "software engineering jobs will grow by 25% from 2021 to 2031, much faster than the average for all occupations" }), ". This growth is driven by the increasing demand for mobile applications, cloud computing, artificial intelligence, cybersecurity, and big data solutions. Additionally, engineers have opportunities to work in various industries such as healthcare, finance, automotive, aerospace, and tech, offering flexibility and innovation in career paths"] }) }), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faCircleArrowDown, color: 'white', style: {
                                    color: theme.palette.common.black,
                                    fontSize: '2rem',
                                } }), onClick: function () {
                                return handleNavigate('/career-day', '#career-day-the-beginning');
                            } })] }) }), _jsx(PageSection, { sectionId: 'career-day-the-beginning', style: {
                    background: 'transparent',
                    padding: 0,
                    minHeight: '80vh',
                }, children: _jsxs(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }, children: [_jsx(CDHowIStartedSection, {}), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faCircleArrowDown, color: 'white', style: {
                                    color: theme.palette.common.white,
                                    fontSize: '2rem',
                                } }), onClick: function () {
                                return handleNavigate('/career-day', '#career-day-how-to-start');
                            } })] }) }), _jsx(PageSection, { sectionId: 'career-day-how-to-start', style: {
                    background: theme.palette.common.white,
                    padding: 0,
                    minHeight: '80vh',
                }, children: _jsxs(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }, children: [_jsx(CDHowToStartSection, {}), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faCircleArrowDown, color: 'white', style: {
                                    color: theme.palette.common.black,
                                    fontSize: '2rem',
                                } }), onClick: function () {
                                return handleNavigate('/career-day', '#career-day-cool-demo');
                            } })] }) }), _jsx(PageSection, { sectionId: 'career-day-cool-demo', style: {
                    background: theme.palette.common.black,
                    padding: 0,
                    width: '100%',
                    minHeight: '85vh',
                }, children: _jsxs(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }, children: [_jsx(CDCoolExampleSection, {}), _jsx(AppIconButton, { disabled: false, icon: _jsx(FontAwesomeIcon, { icon: faCircleArrowDown, color: 'white', style: {
                                    color: theme.palette.common.white,
                                    fontSize: '2rem',
                                } }), onClick: function () { return handleNavigate('/career-day', 'career-day-end'); } })] }) }), _jsx(PageSection, { sectionId: 'career-day-end', style: {
                    background: theme.palette.common.white,
                    padding: 0,
                    minHeight: '80vh',
                }, children: _jsx(Box, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }, children: _jsx(CDEngineerFactsSection, { title: 'Word Of Encouragement', fact: _jsxs(Typography, { variant: 'h4', style: {
                                color: theme.palette.common.black,
                                textAlign: 'center',
                            }, children: ["One of the best things about coding is that", ' ', _jsx(TextHighlight, { children: "the things you create are yours" }), ". Whether it\u2019s a game, an app, or even a funny animation, it\u2019s something you made with your own skills.", ' ', _jsx(TextHighlight, { children: "The joy of seeing your ideas come to life is one of the greatest rewards of being a software engineer." })] }) }) }) })] }));
};
export default CareerDay;
