/*App*/
export var FONT_FAMILY = 'Roboto, Public Sans, sans-serif';
export var TEST_TOKEN_TEXT = 'The rapid advancement of technology has dramatically transformed the way we live and work. From smartphones to artificial intelligence, these innovations have not only made our lives more convenient but have also reshaped industries across the globe. As businesses adapt to this new digital landscape, the need for continuous learning and upskilling has never been more critical. Those who embrace change and keep pace with technological advancements will be well-positioned for future success, while those who resist may find themselves struggling to remain competitive in an increasingly dynamic world.';
export var CHANGE_LOG_ENTRIES = [
    {
        version: '1.0.0',
        date: 'October 2024',
        description: 'Initial Release of Token Quotas; Basic token counting functionality; User-friendly interface with responsive design.',
    },
];
export var HELP_LINKS = [
    {
        url: 'https://platform.openai.com/docs/concepts',
        title: 'OpenAI Documentation',
    },
    {
        url: 'https://platform.openai.com/tokenizer',
        title: 'OpenAI Tokenizer',
    },
    {
        url: 'https://www.ibm.com/docs/en/watsonx/saas?topic=solutions-tokens',
        title: 'IBM WhatIs Tokenization',
    },
];
export var LANGUAGE_MODELS = [
    {
        value: 1,
        label: 'GPT-4',
        encoding: 'gpt-4-turbo',
        description: 'GPT-4 is the fourth iteration of OpenAI’s Generative Pre-trained Transformer (GPT) series. It is a state-of-the-art language model that can generate human-like text based on the input it receives. GPT-4 has 1 trillion parameters and is capable of performing a wide range of natural language processing tasks.',
        tokenLimit: 8192,
    },
    {
        value: 2,
        label: 'GPT-4 Turbo',
        encoding: 'gpt-4-turbo',
        description: "Represents OpenAI's GPT-4 Turbo model, the successor to GPT-3 with 1 trillion parameters",
        tokenLimit: 8192,
    },
    {
        value: 3,
        label: 'GPT-3.5',
        encoding: 'gpt-3.5-turbo',
        description: 'GPT-3.5 is the third iteration of OpenAI’s Generative Pre-trained Transformer (GPT) series. It is a powerful language model that can generate human-like text based on the input it receives. GPT-3.5 has 175 billion parameters and is capable of performing a wide range of natural language processing tasks.',
        tokenLimit: 4096,
    },
    {
        value: 4,
        label: 'Davinci',
        encoding: 'text-davinci-003',
        description: "Represents GPT-3's Davinci model, one of the most capable models in the GPT-3 series for tasks that require a deep understanding of language",
        tokenLimit: 4096,
    },
    {
        value: 5,
        label: 'Babbage',
        encoding: 'text-babbage-001',
        description: "Represents GPT-3's Babbage model, optimized for tasks that require a more focused and concise response",
        tokenLimit: 4096,
    },
];
export var BOOLEAN_CHECKBOX_OPTIONS = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
];
export var HOWTO_STEPS = [
    {
        title: 'Step 1',
        text: 'Select the language model that you want to use for token counting. Different models may count tokens differently.',
    },
    {
        title: 'Step 2',
        text: 'Enter the text you want to analyze in the input field labeled "Text".',
    },
    {
        title: 'Step 3',
        text: 'Click the "Analyze" button to calculate the token count and other data about the text you entered.',
    },
];
export var CAREER_EVENTS = [
    {
        organization: 'Microsoft',
        year: '2022',
        month: 'August',
        title: 'Software Engineer II',
        description: 'I was promoted to Software Engineer II after demonstrating strong technical skills and leadership qualities. Led the development of a new feature for the company’s flagship product, collaborating with cross-functional teams to deliver high-quality software on schedule. Received positive feedback from stakeholders and customers for the successful launch of the feature.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
    {
        organization: 'Microsoft',
        year: '2020',
        month: 'April',
        title: 'Software Engineer I',
        description: 'I worked on the CFS Statutory Compliance team where I was responsible for developing and maintaining the CFS (Common Financial Services) application. The application is a large scale .NET application that is used by all of Microsoft’s financial services teams. I worked on a variety of different technologies including .NET Core, Angular, Azure Services, and much more. I was also responsible for maintaining the application’s CI/CD pipeline and ensuring that the application was always up and running.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
    {
        organization: 'Microsoft',
        year: '2019',
        month: 'August',
        title: 'Software Engineer (Contract)',
        description: 'I was a part of the CSS Engineering (Customer Service & Support) team. We are a team of developers in charge of maintaining and distributing the PVA (Power Virtual Agent) application. The application spans a range of different technologies including .NET Core, JS/Typescript, React, Redux, Azure Services and much more.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
    {
        organization: 'Ekos',
        year: '2015',
        month: 'Jan',
        title: 'Software Developer',
        description: 'I worked as a full stack developer in a very fast paced development environment. I have built and helped maintain integral parts of the Ekos platform including data import and export functionality, payment/credit functionality, and UI functionality. I work first hand with our support team as well utilizing high product knowledge to debug any issues.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/ekos_icon.png',
    },
    {
        organization: 'Automated Conveyor Systems',
        year: '2012',
        month: 'June',
        title: 'Computer Systems Engineer',
        description: 'As a systems engineer I worked with our electrical engineers to produce high functioning code that controlled automated machines from conveyor belts to large automated transfer carts. My job included multiple on site visits to locations around the country where our product was tested and implemented. Most of the code I wrote was in C++ or SQL with very little UI influence. Aside from development, designed networking blueprints that were laid out in very large plants and worked first hand with executives of different companies like Georgia Pacific and Rock Tenn to customize projects for their specific locations.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/acs_icon.png',
    },
    {
        organization: 'University Of Lynchburg',
        year: '2009',
        month: 'January',
        title: 'Assistant Web Developer',
        description: 'It was my job as a junior developer to help with different parts of maintaining the school website. My tasks here were small but beneficial preparing my mind for work outside of school. A lot of what I did was routine updates to different parts of the website like the breakfast, lunch and dinner menu through XML as well as adding any new information about upcoming events for the week.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/lynchburg_icon.png',
    },
];
export var PROJECTS = [
    {
        title: 'Token Quotas Online Tokenizer',
        description: 'As I take on more projects dealing with AI and machine learning, I decided to create a tool that would help me calculate token counts for different language models and allow me to optimize them.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_token_quotas.png',
        tags: ['React', 'Typescript', 'WordPress'],
    },
    {
        title: 'Copilot Memory (2024 Global Hackathon)',
        description: 'I entered the Hackathon with a team of engineers across the company. We built a Copilot plugin that allows users to save memories and recall them.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_copilot_memory.png',
        tags: ['Copilot', 'Azure OpenAI', 'React.js', 'Typescript', 'Python'],
    },
    {
        title: 'Tech Navigator Mentorship Platform',
        description: "I have always taken time to help kids see the value in learning to program and think like an enginner. The Tech Navigator program was something I started at my daughter's school to help kids learn the basics of programming and how to think like an engineer.",
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_tech_navigator.png',
        tags: ['Canva', 'WordPress', 'MySQL'],
    },
    {
        title: 'Fiscal Year Calculator Extension',
        description: 'Because I dealt with fiscal year calculations a lot in my role, I decided to create a Chrome extension that would allow me to quickly calculate fiscal years from any date on the web.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thubnail-fiscalyear-calculator.png',
        tags: ['Chrome Web Store', 'Firebase', 'React.js', 'Typescript'],
    },
    {
        title: 'What Time Is It Extension',
        description: 'This was another tool that I built to help myself and other quickly convert the current time, or an entered time, to various time zones.  This is something that helps me at work as I work and troubleshoot acrross multiple time zones.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail-what-time-is-it.png',
        tags: ['Chrome Web Store', 'Firebase', 'React.js', 'Typescript'],
    },
    {
        title: 'Relativ.ai',
        description: 'I was approached by a friend to help him with a project to facilitate screening interviews with a vitual AI interviewer. I setup the infrastructure and wrote the frontend UI, backend API and Python API to connect to OpenAI.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_relative_ai.png',
        tags: ['C#', 'GraphQL', 'OpenAI', 'React.js', 'Typescript', 'Python'],
    },
    {
        title: 'Virtual Agent Project (Microsoft)',
        description: 'The team I was on was tasked with taking on the Skylight project from another team in Redmond.  I took the initiative and worked to develop the initial project UI infrastructure that was the base for entire frontned.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_virtual_agent.png',
        tags: ['React,js', 'Typescript', 'PostgreSQL'],
    },
    {
        title: 'Freelance Website Builder',
        description: 'As I grew in my career I started to take on freelance website projects. I built numerous websites for small businesses and some of those sites are still in use today.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_c3_church.png',
        tags: ['WordPress', 'Divi', 'CSS', 'Javascript'],
    },
    {
        title: 'Auto Deployment Tool (Ekos)',
        description: 'Because most of our resources were on prem and I did a lot of the production deployments manually at the time, I created a tool that packaged all of the production DLL files and automatically deploy them to the chosen environment web server.',
        link: '',
        image: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/thumbnail_ekos.png',
        tags: ['C#', 'SQL', 'Powershell'],
    },
];
export var LIFE_EVENTS = [
    {
        organization: 'Little Kid',
        year: '1998',
        month: 'January',
        title: 'Software Engineer II',
        description: 'I was promoted to Software Engineer II after demonstrating strong technical skills and leadership qualities. Led the development of a new feature for the company’s flagship product, collaborating with cross-functional teams to deliver high-quality software on schedule. Received positive feedback from stakeholders and customers for the successful launch of the feature.',
        avatarSrc: 'https://strsharedeus.blob.core.windows.net/apps/evanellison/images/windows-icon.png',
    },
];
