import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import theme from '../../Theming/DefaultTheme';
var sectionStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    padding: 10,
    height: '60vh',
    background: 'transparent',
};
var quoteStyle = {
    color: theme.palette.common.white,
    fontSize: '2rem',
    textAlign: 'center',
    padding: '20px',
};
var QuoteSection = function () {
    return (_jsx(Box, { style: sectionStyle, children: _jsx(Typography, { style: quoteStyle, children: "\"The only limit to our realization of tomorrow is our doubts of today.\"" }) }));
};
export default QuoteSection;
