import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, Divider, useTheme, useMediaQuery, Avatar, Card, CardContent, } from '@mui/material';
import { getPageSectionStyle } from '../../Theming/globalStyles';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, } from '@mui/lab';
import { CAREER_EVENTS } from '../../../constants';
var dividerStyle = {
    width: 100,
    margin: '0px 0px 50px 0px',
};
var sectionStyling = {
    background: 'linear-gradient(to top, #dfe9f3 0%, white 100%)',
};
var CareerTimelineSection = function () {
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsxs(Box, { style: getPageSectionStyle(isMobile, sectionStyling), children: [_jsx(Typography, { variant: 'h3', component: 'h2', style: { marginBottom: 10 }, children: "Career Timeline (12 years)" }), _jsx(Divider, { style: dividerStyle }), _jsx(Timeline, { position: 'alternate', children: CAREER_EVENTS.map(function (event, index) { return (_jsxs(TimelineItem, { style: { minHeight: 400 }, children: [_jsxs(TimelineOppositeContent, { children: [_jsx(Typography, { variant: 'body1', color: 'textSecondary', children: event.organization }), _jsxs(Typography, { variant: 'body2', color: 'textSecondary', children: [event.month, "-", event.year] })] }), _jsxs(TimelineSeparator, { children: [_jsx(Avatar, { src: event.avatarSrc, alt: event.title, style: {
                                        width: 60,
                                        height: 60,
                                    } }), index < CAREER_EVENTS.length - 1 && _jsx(TimelineConnector, {})] }), _jsx(TimelineContent, { children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: 'h4', component: 'h2', children: event.title }), _jsx(Typography, { variant: 'body2', children: event.description })] }) }) })] }, index)); }) })] }));
};
export default CareerTimelineSection;
